import auth0 from "./auth0";

const persistAuthResult = async authResult => {
  let profile;
  profile = await auth0.client.userInfo(authResult.accessToken);
  localStorage.setItem("authResult", JSON.stringify(authResult));
  return {
    authResult,
    currentMillis: (new Date()).getTime(),
    profile,
  };
};

const getAuthData = async () => {
  try {
    let authResult;
    if (window.location.hash) {
      authResult = await auth0.parseHash({ hash: window.location.hash });
      window.location.hash = "";
    } else if (localStorage.getItem("authResult")) {
      authResult = await auth0.checkSession({});
    }
    if (authResult) {
      return await persistAuthResult(authResult);
    }
  } catch (err) {
    console.error(err);
  }
  return null;
};

const init = async elmApp => {
  elmApp.ports.auth0authorize.subscribe(async () => await auth0.authorize());
  elmApp.ports.auth0signOut.subscribe(() => localStorage.removeItem("authResult"));
  elmApp.ports.auth0checkSession.subscribe(async () => {
    try {
      const authResult = await auth0.checkSession({});
      const signedInUser = await persistAuthResult(authResult);
      elmApp.ports.auth0authResult.send({ ok : signedInUser });
    } catch (err) {
      console.error(err);
      elmApp.ports.auth0authResult.send({ err : err });
    }
  });
};

export {
  init,
  getAuthData
};
