import { WebAuth } from "auth0-js";

const auth0 = new WebAuth({
  clientID: "FHD5M1RWIG4ZXjjDnXJGuK95lf87uoCv",
  domain: "fysi.eu.auth0.com",
  redirectUri: window.origin,
  responseType: "token id_token",
  scope: "openid profile email"
});

const authorize = () => auth0.authorize();
const checkSession = options => new Promise((resolve, reject) =>
  auth0.checkSession(options, (err, authResult) => {
    if (err) {
      reject(err);
    } else {
      resolve(authResult);
    }
  }));
const parseHash = options => new Promise((resolve, reject) =>
  auth0.parseHash(options, (err, authResult) => {
    if (err) {
      reject(err);
    } else {
      resolve(authResult);
    }
  }));

const userInfo = accessToken => new Promise((resolve, reject) =>
  auth0.client.userInfo(accessToken, (err, user) => {
    if (err) {
      reject(err);
    } else {
      resolve(user);
    }
  }));
const client = { userInfo };

export default {
  authorize,
  checkSession,
  client,
  parseHash,
};
