module Main exposing (main)

import Authentication exposing (Authentication)
import Authentication.Auth0 as Auth0
import Basics.Extra exposing (uncurry)
import Browser exposing (Document, UrlRequest(..))
import Browser.Dom
import Browser.Events
import Browser.Navigation
import ConfirmationDialog
import Console
import Context exposing (Context)
import Effect exposing (Effect)
import Error
import Flags exposing (Flags)
import Html exposing (Html)
import Html.Attributes exposing (class, classList)
import I18Next
import Json.Decode
import Lottie
import Material.Button as Button
import Material.Snackbar as Snackbar
import Material.Theme as Theme
import Material.Typography as Typography
import Maybe.Extra as Maybe
import Page exposing (Page)
import Random.Pcg.Extended as Random exposing (Seed)
import Result.Extra as Result
import Route
import Scale
import Screen exposing (Screen)
import Task
import Transition exposing (Transition)
import Translations
import Ui.Page
import Url exposing (Url)
import UserError exposing (UserError)


main : Program Flags Model Msg
main =
    Browser.application
        { init = init
        , onUrlChange = UrlChanged
        , onUrlRequest = LinkClicked
        , subscriptions = subscriptions
        , update = update
        , view = view
        }


type Model
    = Model (Context Msg) State


type State
    = Authenticating Url
    | Loaded Authentication (Transition Page)
    | Loading Authentication Url


type Msg
    = AuthenticationChanged Authentication
    | AuthenticationUpdated Authentication.Msg
    | Effected Msg
    | Errored { resetTransition : Bool } UserError
    | LinkClicked UrlRequest
    | PageLoaded ( Page, Effect Msg )
    | ResetViewport
    | Resized Screen
    | SignInClicked
    | SignedOut
    | UpdateConfirmationDialog ConfirmationDialog.Msg
    | UpdatePage Page.Msg
    | UrlChanged Url
    | ViewportReset


init : Flags -> Url -> Browser.Navigation.Key -> ( Model, Cmd Msg )
init flags url navKey =
    let
        authentication =
            Maybe.map Authentication.init flags.authData

        translations =
            Json.Decode.decodeValue I18Next.translationsDecoder flags.translations
                |> Result.extract (always I18Next.initialTranslations)

        context =
            { confirmationDialog = ConfirmationDialog.init
            , navKey = navKey
            , production = flags.production
            , rootUrl =
                { url
                    | fragment = Nothing
                    , path = "/"
                    , query = Nothing
                }
            , scale = Scale.Lg
            , seed = newSeed
            , snackbarQueue = Snackbar.initialQueue
            , translations = translations
            }

        ( seed, newSeed ) =
            Random.step Random.independentSeed
                (uncurry Random.initialSeed flags.seed)
    in
    Tuple.mapFirst (Model context) <|
        case authentication of
            Just authentication_ ->
                ( Loading authentication_ url
                , loadPage context authentication_ seed url
                )

            Nothing ->
                ( Authenticating url
                , Cmd.none
                )


showError : String -> ( Model, Cmd msg ) -> ( Model, Cmd msg )
showError string ( Model context state, cmd ) =
    ( Model
        { context
            | snackbarQueue =
                Snackbar.addMessage
                    (Snackbar.message string)
                    context.snackbarQueue
        }
        state
    , cmd
    )


loadPage : Context msg -> Authentication -> Seed -> Url -> Cmd Msg
loadPage context authentication seed url =
    Page.init context authentication seed url
        |> Task.attempt
            (Result.unpack (Errored { resetTransition = True })
                (PageLoaded << Tuple.mapSecond (Effect.map UpdatePage))
            )


subscriptions : Model -> Sub Msg
subscriptions (Model context state) =
    let
        authentication =
            case state of
                Authenticating _ ->
                    Nothing

                Loaded authentication_ _ ->
                    Just authentication_

                Loading authentication_ _ ->
                    Just authentication_
    in
    Sub.batch
        [ Browser.Events.onResize Screen
            |> Sub.map Resized
        , Maybe.unwrap Sub.none
            (Authentication.subscription
                { changed = AuthenticationChanged
                , updated = AuthenticationUpdated
                }
            )
            authentication
        ]


update : Msg -> Model -> ( Model, Cmd Msg )
update msg ((Model context state) as model) =
    let
        { navKey } =
            context

        canonicalizeUrl oldUrl page =
            let
                newUrl =
                    Page.toUrl context.rootUrl page
            in
            if newUrl /= oldUrl then
                Browser.Navigation.replaceUrl context.navKey (Url.toString newUrl)

            else
                Cmd.none

        appendCmd cmd =
            Tuple.mapSecond (Cmd.batch << (::) cmd << List.singleton)
    in
    case ( msg, state ) of
        ( UpdateConfirmationDialog msg_, _ ) ->
            let
                ( newConfirmationDialog, maybeConfirmMsg ) =
                    ConfirmationDialog.update msg_ context.confirmationDialog

                newContext =
                    { context
                        | confirmationDialog = newConfirmationDialog
                    }

                newModel =
                    Model newContext state
            in
            Maybe.unwrap ( newModel, Cmd.none )
                (\confirmMsg -> update confirmMsg newModel)
                maybeConfirmMsg

        ( AuthenticationChanged _, Authenticating url ) ->
            ( Model context (Authenticating url)
            , Cmd.none
            )

        ( AuthenticationChanged authentication, Loaded _ page ) ->
            ( Model context (Loaded authentication page)
            , Cmd.none
            )

        ( AuthenticationChanged authentication, Loading _ url ) ->
            ( Model context (Loading authentication url)
            , Cmd.none
            )

        ( AuthenticationUpdated msg_, Authenticating _ ) ->
            ( model, Cmd.none )

        ( AuthenticationUpdated msg_, Loaded authentication url ) ->
            -- TODO refactor
            let
                ( newAuthentication, cmd, maybeRenewalError ) =
                    Authentication.update msg_ authentication

                renewalFailed _ =
                    Error.custom "Renewal failed"
                        |> UserError.fromError "Sie wurden zu Ihrer Sicherheit abgemeldet. Bitte Sie sich erneut an."
                        |> Errored { resetTransition = False }
                        |> Task.succeed
                        |> Task.perform identity
            in
            ( Model context (Loaded newAuthentication url)
            , Cmd.batch
                [ cmd
                , maybeRenewalError
                    |> Maybe.unwrap Cmd.none renewalFailed
                ]
            )

        ( AuthenticationUpdated msg_, Loading authentication url ) ->
            -- TODO refactor
            let
                ( newAuthentication, cmd, maybeRenewalError ) =
                    Authentication.update msg_ authentication

                renewalFailed _ =
                    Error.custom "Renewal failed"
                        |> UserError.fromError "Sie wurden zu Ihrer Sicherheit abgemeldet. Bitte Sie sich erneut an."
                        |> Errored { resetTransition = False }
                        |> Task.succeed
                        |> Task.perform identity
            in
            ( Model context (Loading newAuthentication url)
            , Cmd.batch
                [ cmd
                , maybeRenewalError
                    |> Maybe.unwrap Cmd.none renewalFailed
                ]
            )

        ( Effected msg_, _ ) ->
            update msg_ model

        ( Errored { resetTransition } userError, _ ) ->
            ( case state of
                Authenticating _ ->
                    model

                Loaded authentication transitionedPage ->
                    Model context
                        (Loaded authentication
                            ((if resetTransition then
                                Transition.cancel

                              else
                                identity
                             )
                                transitionedPage
                            )
                        )

                Loading authentication _ ->
                    model
            , logError userError
            )
                |> showError userError.userMessage

        ( LinkClicked urlRequest, _ ) ->
            case urlRequest of
                External url ->
                    ( model, Browser.Navigation.load url )

                Internal url ->
                    ( model, Browser.Navigation.pushUrl navKey (Url.toString url) )

        ( PageLoaded _, Authenticating _ ) ->
            ( model, Cmd.none )

        ( PageLoaded ( page, effect ), Loading authentication url ) ->
            ( Model context (Loaded authentication (Transition.succeed page))
            , effect
            )
                |> handleEffect authentication
                |> appendCmd (canonicalizeUrl url page)

        ( PageLoaded ( page, effect ), Loaded authentication currentTransition ) ->
            ( Model context (Loaded authentication (Transition.succeed page))
            , effect
            )
                |> handleEffect authentication
                |> appendCmd (canonicalizeUrl (Page.toUrl context.rootUrl (Transition.page currentTransition)) page)

        ( ResetViewport, _ ) ->
            ( model, resetViewport )

        ( Resized screen, _ ) ->
            ( Model { context | scale = Scale.fromScreen screen } state
            , Cmd.none
            )

        ( SignedOut, _ ) ->
            let
                url =
                    case state of
                        Authenticating url_ ->
                            url_

                        Loaded _ transitionedPage ->
                            Page.toUrl context.rootUrl (Transition.page transitionedPage)

                        Loading _ url_ ->
                            url_

                newContext =
                    { context
                        | seed = newSeed
                    }

                ( _, newSeed ) =
                    Random.step Random.independentSeed context.seed
            in
            ( Model newContext (Authenticating url), Authentication.signOut )

        ( SignInClicked, _ ) ->
            ( model, Authentication.authorize Auth0.defaultOpts )

        ( UpdatePage _, Authenticating _ ) ->
            ( model, Cmd.none )

        ( UpdatePage pageMsg, Loaded authentication transitionedPage ) ->
            Transition.update (Page.update context authentication pageMsg) transitionedPage
                |> Tuple.mapFirst (Model context << Loaded authentication)
                |> Tuple.mapSecond (Effect.map UpdatePage)
                |> handleEffect authentication

        ( UpdatePage _, Loading _ _ ) ->
            ( model, Cmd.none )

        ( UrlChanged url, Authenticating _ ) ->
            ( Model context (Authenticating url), Cmd.none )

        ( UrlChanged url, Loading authentication _ ) ->
            let
                newContext =
                    { context
                        | seed = newSeed
                    }

                ( seed, newSeed ) =
                    Random.step Random.independentSeed context.seed
            in
            ( Model newContext (Loading authentication url)
            , loadPage newContext authentication seed url
            )

        ( UrlChanged url, Loaded authentication page ) ->
            let
                newContext =
                    { context | seed = newSeed }

                ( seed, newSeed ) =
                    Random.step Random.independentSeed context.seed
            in
            loadPage newContext authentication seed url
                |> Transition.transitionTo page url
                |> Tuple.mapFirst (Model newContext << Loaded authentication)

        ( ViewportReset, _ ) ->
            ( model, Cmd.none )


logError : UserError -> Cmd msg
logError { errorMessage, userMessage } =
    Console.error errorMessage


handleEffect : Authentication -> ( Model, Effect Msg ) -> ( Model, Cmd Msg )
handleEffect authentication =
    Effect.handle
        (\((Model context state) as model) effect ->
            case effect of
                Effect.Confirm config ->
                    let
                        newContext =
                            { context
                                | confirmationDialog = ConfirmationDialog.open config
                            }
                    in
                    ( Model newContext state, Cmd.none )

                Effect.Graphql mkTask ->
                    ( model
                    , mkTask (Authentication.getToken authentication)
                        |> Task.attempt (Result.unpack (Errored { resetTransition = False }) Effected)
                    )

                Effect.Navigate route ->
                    ( model, Task.perform LinkClicked (Task.succeed (Browser.Internal (Route.toUrl context.rootUrl route))) )

                Effect.SignOut ->
                    ( model, Task.perform identity (Task.succeed SignedOut) )

                Effect.Task task ->
                    ( model
                    , task authentication
                        |> Task.attempt (Result.unpack (Errored { resetTransition = False }) Effected)
                    )
        )


resetViewport : Cmd Msg
resetViewport =
    Task.perform (always ViewportReset) (Browser.Dom.setViewport 0 0)


view : Model -> Document Msg
view ((Model context state) as model) =
    { body =
        [ Html.div
            [ classList
                [ ( "app", True )
                , ( "app--loading"
                  , case state of
                        Authenticating _ ->
                            False

                        Loaded _ transitionedPage ->
                            Transition.isTransitioning transitionedPage

                        Loading _ _ ->
                            True
                  )
                ]
            ]
            [ case state of
                Authenticating _ ->
                    viewSignIn context

                Loaded _ transitionedPage ->
                    Page.view context (Transition.page transitionedPage)
                        |> Html.map UpdatePage

                Loading _ _ ->
                    viewLoading context
            ]
        , ConfirmationDialog.view UpdateConfirmationDialog context.confirmationDialog
            |> Maybe.withDefault (Html.text "")
        ]
    , title = Translations.fysiweb context.translations
    }


viewLoading : Ui.Page.Config c -> Html msg
viewLoading config =
    Ui.Page.view config <|
        [ Html.div [ class "loading" ]
            [ Html.p
                [ class "loading__hint"
                , Theme.textHintOnBackground
                , Typography.body2
                ]
                [ Html.text "Loading.." ]
            ]
        ]


viewSignIn : Ui.Page.Config c -> Html Msg
viewSignIn config =
    Ui.Page.view config <|
        [ Html.div [ class "sign-in" ]
            [ Lottie.view [ class "sign-in__background-animation" ] "https://assets5.lottiefiles.com/packages/lf20_vdua3xbn.json"
            , Html.div [ class "sign-in__info-block" ]
                [ Html.p
                    [ class "sign-in__text"
                    , Typography.body2
                    ]
                    [ Html.text "Thanks for signing up for our beta program! We can't wait to hear your thoughts." ]
                , Html.div [ class "sign-in__button" ]
                    [ Button.raised
                        (Button.config
                            |> Button.setOnClick SignInClicked
                        )
                        "Sign in"
                    ]
                ]
            ]
        ]
