const init = elmApp => {
  elmApp.ports.console.subscribe(args => {
    try {
      const [method, ...params] = args;
      console[method](...params);
    } catch (e) {
      console.error(`port: console: ${e.message}`);
    }
  });
};

export {
  init
};
